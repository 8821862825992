import React from 'react';
import Button from '../utilities/Button';
import Input from '../utilities/Input';
import styles from './RegisterAvailableCupom.module.css';
import useFetch from '../../Hooks/useFetch';
import { AVA_CUPOM_POST } from '../../ApiSettings';

const RegisterAvailableCupom = () => {
  const { setError, loading, error, request } = useFetch();
  const [success, setSuccess] = React.useState(false);
  async function handleSubmit(event) {
    event.preventDefault();

    const valorCupom = event.target.querySelector(
      `input[name='valorCupom']`,
    ).value;
    const valorVenda = event.target.querySelector(
      `input[name='valorVenda']`,
    ).value;
    const validade = event.target.querySelector(`input[name='validade']`).value;

    const nomeCupom = `R$${valorCupom} por R$${valorVenda}`;

    const post_body = {
      nome: nomeCupom,
      valor_cupom: valorCupom,
      valor_venda: valorVenda,
      validade: validade,
    };
    const token = window.localStorage.getItem('token');
    const { url, options } = AVA_CUPOM_POST(post_body, token);

    const { response } = await request(url, options);
    if (response.ok) {
      setSuccess({
        nomeCupom: nomeCupom,
        validadeCupom: validade,
      });
    }
  }

  return (
    <div className={styles.RegisterAvailableCupom}>
      <h2>Criar cupom</h2>
      {success ? (
        <div className={styles.success}>
          <p>O seguinte cupom foi criado com sucesso:</p>
          <span style={{ fontWeight: '600' }}>{success.nomeCupom}</span>
          <span>Validade: {success.validadeCupom} dias</span>
          <Button onClick={() => setSuccess(false)}>Criar novo cupom</Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={styles.formGrid}>
            <div>
              <Input label="Valor do cupom" type="number" name="valorCupom" />
              <span className={styles.description}>
                Esse é o valor que o cliente terá de saldo para utilizar
              </span>
            </div>
            <div>
              <Input label="Valor de venda" type="number" name="valorVenda" />
              <span className={styles.description}>
                Esse é o valor que será cobrado pelo cupom
              </span>
            </div>
            <div>
              <Input label="Validade" type="number" name="validade" />
              <span className={styles.description}>
                Defina a validade do cupom em dias
              </span>
            </div>
            <Button>Criar</Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default RegisterAvailableCupom;
