import React from 'react';
import CustomerDetailsField from './CustomerDetailsField';
import styles from './CustomerDetails.module.css';
import CupomCustomerDetail from './CupomCustomerDetail';
import { DataContext } from '../../Contexts/DataContext';

const CustomerDetails = ({ customer, setMasterModal }) => {
  const { cupons } = React.useContext(DataContext);

  const customerCupons = cupons.filter((cupom) => {
    return +cupom.metafields.customer_id[0] === +customer.ID;
  });

  let validCuponsQty = 0;
  let inactiveCuponsQty = 0;
  customerCupons.forEach((cupom) => {
    const is_expired = cupom.metafields.is_expired != '';
    const is_outfunded = cupom.metafields.is_outfunded != '';
    if (!is_expired && !is_outfunded) {
      validCuponsQty += 1;
    } else if (is_expired || is_outfunded) {
      inactiveCuponsQty += 1;
    }
  });

  return (
    <>
      <div>
        <h2>Detalhes do cliente</h2>

        <div className={styles.CustomerDetails}>
          <div>
            <h3>Dados cadastrados</h3>
            <CustomerDetailsField
              label={'Nome'}
              value={customer.data.metafields.first_name}
            />
            <CustomerDetailsField
              label={'Celular'}
              value={customer.data.metafields.celular}
            />
            <CustomerDetailsField
              label={'E-mail'}
              value={customer.data.user_email}
            />
          </div>
          <div>
            <h3>Cupons</h3>

            {validCuponsQty === 0 ? (
              <>
                <p>Nenhum cupom no momento...</p>
              </>
            ) : (
              <div>
                <span className="cupomQty">{`${validCuponsQty} cupom(s) ativo`}</span>
                {customerCupons.map((cupom) => {
                  if (
                    cupom.metafields.is_expired == '' &&
                    cupom.metafields.is_outfunded == ''
                  ) {
                    return (
                      <CupomCustomerDetail
                        key={cupom.ID}
                        cupom={cupom}
                        customer={customer}
                        setMasterModal={setMasterModal}
                      />
                    );
                  } else return null;
                })}
              </div>
            )}
            {inactiveCuponsQty > 0 && (
              <>
                <h2 className="h3" style={{ marginTop: '20px' }}>
                  Utilizados
                </h2>
                <span className="cupomQty">{`${inactiveCuponsQty} cupom(s) já utilizados`}</span>
                {customerCupons.map((cupom) => {
                  if (
                    cupom.metafields.is_expired == true ||
                    cupom.metafields.is_outfunded == true
                  ) {
                    return (
                      <CupomCustomerDetail
                        key={cupom.ID}
                        cupom={cupom}
                        customer={customer}
                        setMasterModal={setMasterModal}
                      />
                    );
                  } else return null;
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;
