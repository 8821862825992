import React from 'react';
import { ReactComponent as Info } from '../Assets/icons/document.svg';
import { ReactComponent as Debit } from '../Assets/icons/use-cupom.svg';
import CupomDetails from './Modals/CupomDetails';
import DebitCupom from './Modals/DebitCupom';
import styles from './CuponsListEntry.module.css';
import { UserContext } from '../Contexts/UserContext';

const CupomListEntry = ({
  cupom,
  handleModal,
  hasTooltip = false,
  currentTooltip,
}) => {
  const is_expired = cupom.metafields.is_expired != '';
  const is_outfunded = cupom.metafields.is_outfunded != '';
  const has_pending =
    cupom.metafields.pending_debit != null &&
    cupom.metafields.pending_debit != '';
  const { role } = React.useContext(UserContext);

  const active_tooltip = (targetIcon) => {
    if (hasTooltip) {
      return currentTooltip === cupom.ID;
    } else return false;
  };
  return (
    <li
      className={`flex_space_between align_center ${styles.cupom}`}
      data-status={is_expired || is_outfunded ? 'inactive' : 'active'}
      data-tooltip={active_tooltip()}
    >
      <div className={`${styles.cupomInfo}`}>
        <p>
          {role === 'administrator'
            ? cupom.post_title
            : cupom.post_title.split(' - ')[1]}
        </p>
        <span>
          Validade: {cupom.metafields.expire_date} {is_expired && ' - EXPIRADO'}
          {is_outfunded && ' - FINALIZADO'}
        </span>
      </div>

      <div className={styles.saldo}>
        <p>Disponível</p>
        <span>R$ {cupom.metafields.current_value},00</span>
        {has_pending && (
          <p
            className={styles.pendingAlert}
            data-tooltip={`Pagamento pendente: R$ ${
              JSON.parse(cupom.metafields.pending_debit).amount
            },00`}
          >
            Pendente
          </p>
        )}
      </div>

      <div className={`cupomListOptions ${styles.cupomOptions}`}>
        <span onClick={() => handleModal(<CupomDetails cupom={cupom} />)}>
          <Info />
        </span>

        {!is_expired && !is_outfunded && (
          <span
            data-icon="debit"
            className={has_pending ? 'disabled' : null}
            onClick={
              !has_pending
                ? () => {
                    if (role === 'administrator') {
                      handleModal(<DebitCupom cupom={cupom} role={role} />);
                    } else {
                      handleModal(
                        !hasTooltip ? (
                          <DebitCupom
                            cupom={cupom}
                            role={role}
                            handleModal={handleModal}
                          />
                        ) : (
                          ''
                        ),
                      );
                    }
                  }
                : null
            }
          >
            <Debit />
          </span>
        )}

        <span>•••</span>
      </div>
    </li>
  );
};

export default CupomListEntry;
