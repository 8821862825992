import React from 'react';
import Button from '../utilities/Button';
import Loading from '../utilities/Loading';
import SearchInput from '../utilities/SearchInput';
import CustomerListEntry from './CustomerListEntry';
import styles from './ManageCustomers.module.css';
import { DataContext } from '../../Contexts/DataContext';
import AddCustomerModal from '../Modals/AddCustomerModal';

const ManageCustomers = ({ setMasterModal }) => {
  const [queryUsers, setQueryUsers] = React.useState(null);

  const { fetchAllCustomers, customers, loading } =
    React.useContext(DataContext);

  const token = window.localStorage.getItem('token');

  React.useEffect(() => {
    fetchAllCustomers(token);
  }, []);

  // console.log(customers);

  return (
    <section className={styles.customers}>
      <div className="flex_space_between" style={{ alignItems: 'end' }}>
        <h1>Gerenciar clientes</h1>
        <div className="total">
          {customers && (
            <>
              <p style={{ display: 'inline' }}>
                Total de clientes cadastrados:{' '}
              </p>{' '}
              <span>{customers.length}</span>
            </>
          )}
        </div>
      </div>

      <div className={styles.allCustomersPanel}>
        <div className={`searchNavHeader`}>
          {customers && (
            <SearchInput
              type="customers"
              data={customers}
              setQueryData={setQueryUsers}
              placeholder={'Busque por nome...'}
              style={{ maxWidth: '50%', width: '780px' }}
            />
          )}

          <nav className={`subNav`}>
            <div>
              <Button
                onClick={() =>
                  setMasterModal(
                    <AddCustomerModal fetchAllCustomers={fetchAllCustomers} />,
                  )
                }
              >
                Adicionar cliente
              </Button>
            </div>
          </nav>
        </div>

        {customers &&
          !queryUsers &&
          customers.map((user) => {
            return (
              <CustomerListEntry
                user={user}
                key={user.data.ID}
                setMasterModal={setMasterModal}
              />
            );
          })}
        {customers &&
          queryUsers &&
          queryUsers.length > 0 &&
          queryUsers.map((queriedUser) => {
            return (
              <CustomerListEntry
                user={queriedUser}
                key={queriedUser.data.ID}
                setMasterModal={setMasterModal}
              />
            );
          })}
        {customers && queryUsers && queryUsers.length === 0 && (
          <p>Nenhum cliente encontrado.</p>
        )}
      </div>
      {loading && <Loading />}
    </section>
  );
};

export default ManageCustomers;
