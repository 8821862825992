import React from 'react';
import { UserContext } from '../../Contexts/UserContext';
import Button from '../utilities/Button';
import Error from '../utilities/Error';
import Input from '../utilities/Input';

const LoginForm = () => {
  const { userLogin, loading, error } = React.useContext(UserContext);

  function handleSubmit(event) {
    event.preventDefault();
    const username = event.target.querySelector('input[name="username"]').value;
    const password = event.target.querySelector('input[name="password"]').value;
    userLogin(username, password);
  }
  return (
    <div>
      <form onSubmit={handleSubmit} style={{ width: '90%' }}>
        <Input
          label="Usuário"
          name="username"
          style={{ marginBottom: '8px' }}
        />
        <Input label="Senha" name="password" type="password" />
        {loading ? (
          <Button disabled>Aguarde...</Button>
        ) : (
          <Button>Entrar</Button>
        )}
      </form>
      {error && (
        <Error
          error={error}
          styles={{ color: '#f31', margin: '2px 0', fontSize: '.825rem' }}
        />
      )}
    </div>
  );
};

export default LoginForm;
