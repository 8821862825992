import React from 'react';
import Home from './components/Home';
import HeaderBar from './components/HeaderBar';
import { UserStorage } from './Contexts/UserContext';

import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Admin from './components/admin/Admin';

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={'/app'}>
        <UserStorage>
          <HeaderBar />
          <Routes>
            <Route path="/*" element={<Home />} />

            <Route path="/admin/*" element={<Admin />} />
          </Routes>
        </UserStorage>
      </BrowserRouter>
    </div>
  );
}

export default App;
