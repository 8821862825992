import React from 'react';
import Switcher from '../utilities/Switcher';

const RegCupomListEntry = ({ cupom, fetchAllCupons }) => {
  // console.log(cupom);
  return (
    <li
      data-valor={cupom.metafields.valor_saldo}
      data-active={cupom.post_status === 'publish' && 'active'}
    >
      <div>
        <h3>{cupom.metafields.nome}</h3>
        <span className="expire">
          Validade: {cupom.metafields.validade} dias
        </span>
      </div>
      <div className="reg_cupon_options">
        <Switcher
          status={cupom.post_status}
          post={{ ID: cupom.ID, post_type: 'ava_cupom' }}
          refresh={fetchAllCupons}
        />
        <p className="avaliable">
          {cupom.post_status === 'publish' ? 'Disponível' : 'Indisponível'}
        </p>
      </div>
    </li>
  );
};

export default RegCupomListEntry;
