import React from 'react';
import { AVA_CUPOM_GET, CUPOM_POST } from '../../ApiSettings';
import useFetch from '../../Hooks/useFetch';
import SearchInput from '../utilities/SearchInput';
import styles from './AddCupom.module.css';
import modalStyles from './../../Hooks/Modal.module.css';
import { ReactComponent as CheckIcon } from '../../Assets/icons/check-mark.svg';
import { ReactComponent as CupomIcon } from '../../Assets/icons/ticket.svg';
import useOrderElements from '../../Hooks/useOrderElements';
import Button from '../utilities/Button';
// import { DataContext } from '../../Contexts/DataContext';

const AddCupom = ({ customers, fetchAllCustomers, fetchAllCupons }) => {
  const [queryUsers, setQueryUsers] = React.useState(null);
  const [cupons, setCupons] = React.useState(null);
  const [cuponsTooltip, setCuponsTooltip] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [customer, setCustomer] = React.useState(null);
  const [cupom, setCupom] = React.useState(null);

  const { request, error, loading } = useFetch();
  const { orderElements } = useOrderElements();

  const token = window.localStorage.getItem('token');
  const get_reg_cupons = AVA_CUPOM_GET(token);

  async function fetchData() {
    const cuponsResponse = await request(
      get_reg_cupons.url,
      get_reg_cupons.options,
    );
    setCupons(orderElements(cuponsResponse.json));
  }

  React.useEffect(() => {
    fetchData();
    fetchAllCustomers(token);
  }, []);

  const showCustomersTooltip =
    queryUsers && queryUsers.length > 0 ? true : false;

  function handleSearchInputBlur(event) {
    setQueryUsers(null);
  }

  function selectCustomerFromSearchList({ currentTarget }) {
    setQueryUsers(null);
    const selectedCustomer = queryUsers.flatMap((user) =>
      user.ID === +currentTarget.dataset.customer ? user : [],
    );
    setCustomer(selectedCustomer);
  }

  function selectCupomToAdd({ currentTarget }) {
    const selectedCupom = cupons.flatMap((cpm) => {
      return cpm.ID === +currentTarget.dataset.cupom ? cpm : [];
    });
    setCuponsTooltip(false);
    setCupom(selectedCupom);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    /* POST Cupom */
    const post_cupom_body = { cupom: cupom, customer: customer };
    const post_cupom = CUPOM_POST(post_cupom_body, token);
    const { response, json } = await request(
      post_cupom.url,
      post_cupom.options,
    );
    if (response.ok) {
      console.log(json);
      fetchAllCupons(token, true);
      fetchAllCustomers(token, true);
      setSuccess(true);
    }
    /* End POST Cupom */
  }

  return (
    <div className={styles.addCupom}>
      <h2>Lançar cupom</h2>
      {success ? (
        <div className={styles.success}>
          <p className={modalStyles.successTitle}>Cupom lançado com sucesso.</p>
          <span>Confira abaixo os detalhes:</span>
          <div className={modalStyles.successDetails}>
            <p>Cupom {cupom[0].metafields.nome && cupom[0].metafields.nome}</p>
            <p>
              Validade:{' '}
              {cupom[0].metafields.validade && cupom[0].metafields.validade}{' '}
              dias
            </p>
            <p>
              Cliente:{' '}
              {customer[0].data.metafields.first_name &&
                customer[0].data.metafields.first_name}
            </p>
          </div>
          <p className={styles.emailMessage}>
            Um email foi enviado para o endereço cadastrado com a confirmação
            deste cadastro!
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {customers && (
            <div className={`${styles.formEntry} selectCustomerEntry`}>
              <span>Selecione o cliente:</span>
              {customer ? (
                <div className={`${styles.selectedCustomer} addCupomInput`}>
                  <CheckIcon />
                  <p>{customer[0].data.metafields.first_name}</p>
                  <p className={styles.selectedCustomerContact}>
                    <span>{customer[0].data.metafields.celular}</span> |{' '}
                    <span>{customer[0].data.user_email}</span>
                  </p>
                  <span
                    className={styles.removeCustomer}
                    onClick={() => setCustomer(null)}
                  >
                    x
                  </span>
                </div>
              ) : (
                <SearchInput
                  type="customers"
                  data={customers}
                  setQueryData={setQueryUsers}
                  onBlur={handleSearchInputBlur}
                  className={'addCupomInput'}
                  style={{
                    textAlign: 'center',
                    fontSize: '1.825rem',
                    fontStyle: 'italic',
                  }}
                />
              )}

              {showCustomersTooltip && (
                <div className={`${styles.tooltip}`}>
                  <ul>
                    {queryUsers.map(({ ID, data }) => {
                      return (
                        <li
                          key={ID}
                          onMouseDown={selectCustomerFromSearchList}
                          data-customer={ID}
                        >
                          <p>{data.metafields.first_name}</p>
                          <span>{data.metafields.celular}</span> | {''}
                          <span>{data.user_email}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}

          {cupons && (
            <div className={`${styles.formEntry} selectCupomEntry`}>
              <span>Selecione o cupom:</span>
              <div
                className={`${styles.selectCupom} addCupomInput`}
                onClick={() => setCuponsTooltip(!cuponsTooltip)}
                active={cuponsTooltip ? 'true' : 'false'}
              >
                {cupom && <CupomIcon />}
                {cupom && (
                  <p style={{ fontWeight: '500' }}>
                    Cupom {cupom[0].metafields.nome}
                    <span
                      style={{
                        fontSize: '.7rem',
                        float: 'right',
                        transform: 'translateY(6px)',
                      }}
                    >
                      Val: {cupom[0].metafields.validade} dias
                    </span>
                  </p>
                )}
              </div>
              {cuponsTooltip && (
                <div className={`${styles.cuponsTooltip} ${styles.tooltip}`}>
                  <ul>
                    {cupons.map(({ post_status, ID, metafields }) => {
                      return (
                        post_status === 'publish' && (
                          <li
                            key={ID}
                            data-cupom={ID}
                            onClick={selectCupomToAdd}
                          >
                            <p>{metafields.nome}</p>
                            <span>Validade: {metafields.validade} dias</span>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}
          {loading ? (
            <Button disabled>Aguarde...</Button>
          ) : (
            <Button>Confirmar venda</Button>
          )}
        </form>
      )}
    </div>
  );
};

export default AddCupom;
