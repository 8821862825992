import React from 'react';
import RegisterAvailableCupom from '../Modals/RegisterAvailableCupom';
import RegisteredCupons from '../Modals/RegisteredCupons';
import Button from '../utilities/Button';
import styles from './ManageCupons.module.css';
import { DataContext } from '../../Contexts/DataContext';
import CupomListEntry from '../CupomListEntry';
import SearchInput from '../utilities/SearchInput';
import AddCupom from '../Modals/AddCupom';

const ManageCupons = ({ setMasterModal }) => {
  const [queryCupons, setQueryCupons] = React.useState(null);

  const token = window.localStorage.getItem('token');
  const { fetchAllCupons, fetchAllCustomers, cupons, customers } =
    React.useContext(DataContext);

  // console.log(cupons);
  React.useEffect(() => {
    fetchAllCupons(token);
  }, []);

  return (
    <section className={styles.manageCupons}>
      <div className="flex_space_between align_center">
        <h1>Gerenciar cupons</h1>
      </div>

      <div className={styles.allCuponsPanel}>
        <div className={`searchNavHeader`}>
          {cupons && (
            <SearchInput
              type="cupom"
              data={cupons}
              setQueryData={setQueryCupons}
              placeholder={'Busque por nome do cliente...'}
              style={{ maxWidth: '50%', width: '780px' }}
            />
          )}

          <nav className={`subNav`}>
            <div>
              <Button onClick={() => setMasterModal(<RegisteredCupons />)}>
                Disponíveis
              </Button>
            </div>
            <div>
              <Button
                onClick={() => setMasterModal(<RegisterAvailableCupom />)}
              >
                Criar cupom
              </Button>
            </div>
            <div className={styles.option}>
              <Button
                onClick={() =>
                  setMasterModal(
                    <AddCupom
                      customers={customers}
                      fetchAllCupons={fetchAllCupons}
                      fetchAllCustomers={fetchAllCustomers}
                    />,
                  )
                }
              >
                Lançar cupom
              </Button>
            </div>
          </nav>
        </div>

        <ul className={styles.mainList}>
          {cupons &&
            !queryCupons &&
            cupons.map((cupom) => {
              return (
                <CupomListEntry
                  cupom={cupom}
                  key={cupom.ID}
                  handleModal={setMasterModal}
                />
              );
            })}

          {cupons &&
            queryCupons &&
            queryCupons.length > 0 &&
            queryCupons.map((queriedCupom) => {
              return (
                <CupomListEntry
                  cupom={queriedCupom}
                  key={queriedCupom.ID}
                  handleModal={setMasterModal}
                />
              );
            })}

          {cupons && queryCupons && queryCupons.length === 0 && (
            <p>Nenhum cupom encontrado.</p>
          )}
        </ul>
      </div>
    </section>
  );
};

export default ManageCupons;
