import React from 'react';
import styles from './Modal.module.css';
import { ReactComponent as CloseIcon } from './../Assets/icons/close.svg';

const useModal = (setMasterModal, styleOptions) => {
  function handleCloseModal() {
    document.querySelector('body').classList.remove('overflow_hidden');
    setMasterModal(false);
  }

  const noScrollBody = () => {
    document.querySelector('body').classList.add('overflow_hidden');
    document
      .querySelector('body')
      .addEventListener('mousedown', ({ target }) => {
        target.classList.contains('modalBackground') && handleCloseModal();
      });
  };

  return {
    ModalElement: ({ children }) => {
      return (
        <div className={`${styles.modal} modalBackground`}>
          <div
            className={`${styles.modalInner} animeTop`}
            data-modal-inner
            style={styleOptions && styleOptions}
          >
            {children}
            <span className={styles.close} onClick={() => handleCloseModal()}>
              <CloseIcon />
            </span>
          </div>
          {noScrollBody()}
        </div>
      );
    },
  };
};

export default useModal;
