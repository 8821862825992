import React from 'react';
import useCupom from '../../Hooks/useCupom';
import Button from '../utilities/Button';
import Input from '../utilities/Input';
import styles from './DebitCupom.module.css';
import Error from '../utilities/Error';
import DebitCupomSuccess from './DebitCupomSuccess';
import DebitCupomSubmitted from '../customers/DebitCupomSubmitted';

const DebitCupom = ({ cupom, role, ...props }) => {
  const { debitCupom, debitCupomNotify, success, error, loading, setSuccess } =
    useCupom(+cupom.ID, cupom.post_title);
  const formRef = React.useRef();
  const is_admin = role === 'administrator';
  // console.log(cupom);

  return (
    <div
      className={`${styles.debitCupom} ${
        is_admin ? 'admin' : 'customer animeTop'
      }`}
      data-cupom-id={cupom.ID}
      id="debitCupom"
    >
      {is_admin ? <h1>Debitar cupom</h1> : <h3>Debitar cupom</h3>}
      <Error
        error={error}
        styles={{
          textAlign: 'center',
          color: 'red',
          fontSize: '.875rem',
          marginBottom: '18px',
        }}
      />
      {success &&
        (is_admin ? (
          <DebitCupomSuccess cupom={cupom} formRef={formRef} />
        ) : (
          <DebitCupomSubmitted
            cupom={cupom}
            formRef={formRef}
            handleModal={props.handleModal}
            setSuccess={setSuccess}
          />
        ))}

      <div
        className={`${styles.first_screen} ${is_admin ? 'admin' : 'customer'}`}
        style={{
          height: success ? '0px' : 'auto',
          overflow: success && 'hidden',
        }}
      >
        <div className={styles.cupomData}>
          {is_admin && <span>Dados do cupom</span>}
          <div>
            <p>{cupom.post_title.split(' - ')[1]}</p>
            {is_admin && <p>{cupom.post_title.split(' - ')[0]}</p>}
            <p className="expire">Val: {cupom.metafields.expire_date}</p>
          </div>
          <div>
            <p>Saldo atual: R$ {cupom.metafields.current_value},00</p>
          </div>
        </div>
        <div className={styles.transactionData}>
          {is_admin && <p>Dados da transação</p>}
          <form
            onSubmit={(event) =>
              is_admin ? debitCupom(event) : debitCupomNotify(event)
            }
            ref={formRef}
          >
            <Input
              label="Informe o valor a ser debitado"
              type="number"
              name="debit_value"
            />
            <Input
              HtmlElement="textarea"
              name="debit_description"
              rows={is_admin ? '4' : '1'}
              placeholder="Deixe um comentário..."
            />
            <div className={styles.formBottom}>
              <div>{is_admin && <input type="date" />}</div>
              <div>
                {loading ? (
                  <Button disabled>Aguarde...</Button>
                ) : (
                  <Button>Debitar</Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DebitCupom;
