import React from 'react';
import styles from './HeaderBar.module.css';
import Button from './utilities/Button';
import Input from './utilities/Input';
import { UserContext } from '../Contexts/UserContext';
import Error from './utilities/Error';
import { Link } from 'react-router-dom';

const HeaderBar = () => {
  const { userLogin, userLogout, error, loading, data, login, role } =
    React.useContext(UserContext);

  async function handleSubmit(event) {
    event.preventDefault();
    const username = event.target.querySelector('input[name="username"]').value;
    const password = event.target.querySelector('input[name="password"]').value;
    userLogin(username, password);
  }

  return (
    <section className={styles.headerBar}>
      <div className={`container ${styles.headerBarContainer}`}>
        <Link to="/">
          <div>LOGO</div>
        </Link>
      </div>
    </section>
  );
};

export default HeaderBar;
