import React from 'react';
import Button from '../utilities/Button';
import Input from '../utilities/Input';
import { USER_POST } from '../../ApiSettings';
import useFetch from '../../Hooks/useFetch';
import Error from '../utilities/Error';

const AddCustomerModal = ({ fetchAllCustomers }) => {
  const { loading, request, error, setError } = useFetch();
  const [success, setSuccess] = React.useState(null);

  const token = window.localStorage.getItem('token');

  async function handleSubmit(event) {
    event.preventDefault();

    const email = event.target.querySelector(`input[name='email']`).value;
    const senha = event.target.querySelector(`input[name='senha']`).value;
    const nome = event.target.querySelector(`input[name='nome']`).value;
    const celular = event.target.querySelector(`input[name='celular']`).value;

    const user_post_body = {
      username: email,
      email: email,
      password: senha,
      nome_completo: nome,
      celular: celular,
    };

    const { url, options } = USER_POST(user_post_body);

    const { response, json } = await request(url, options);
    console.log(json);
    if (response.ok) {
      fetchAllCustomers(token, true);
      setError(null);
      setSuccess(true);
    }
  }
  return (
    <div>
      <h2>Adicionar cliente</h2>
      {success ? (
        <div className="addCustomerSuccess">
          <p>Cadastro de cliente realizado com sucesso!</p>
          <p>As informações cadastradas serão enviadas ao e-mail informado.</p>

          <Button onClick={() => setSuccess(null)}>Novo cadastro</Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <Input label="Nome completo" name="nome" />
          <Input label="E-mail" type="e-mail" name="email" validate="email" />
          <Input label="Celular/WhatsApp" name="celular" validate="celular" />
          <Input label="Senha" type="password" name="senha" />
          <Error
            error={error}
            styles={{
              color: '#f31',
              fontSize: '.725rem',
              fontWeight: '600',
              marginTop: '.25rem',
            }}
          />
          {loading ? (
            <Button disabled>Carregando</Button>
          ) : (
            <Button>Cadastrar</Button>
          )}
        </form>
      )}
    </div>
  );
};

export default AddCustomerModal;
