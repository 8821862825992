import React from 'react';
import styles from './Input.module.css';
import { validateTypes } from './InputValidateRegexp';

const Input = ({ label, type, name, validate, HtmlElement, ...props }) => {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(null);
  const [lastTextareaHeigth, setLastTextareaHeigth] = React.useState(null);

  function validateInput(value, type) {
    if (!validateTypes[type].regex.test(value) && value) {
      setError(validateTypes[type].message);
      return false;
    } else {
      setError(null);
      return true;
    }
  }
  if (HtmlElement === 'textarea') {
    return (
      <div className={styles.inputWrapper}>
        <textarea
          className={styles.textarea}
          cols="30"
          rows="5"
          name={name}
          value={value}
          onChange={({ target }) => {
            if (lastTextareaHeigth !== target.scrollHeight) {
              target.style.height = `${target.scrollHeight}px`;
              setLastTextareaHeigth(target.scrollHeight);
            }
            setValue(target.value);
          }}
          {...props}
        ></textarea>
      </div>
    );
  } else {
    return (
      <div className={styles.inputWrapper}>
        <label>
          <span>{label}</span>
          <input
            type={type ? type : 'text'}
            className={styles.input}
            name={name}
            value={value}
            onChange={({ target }) => setValue(target.value)}
            validate={validate ? validate : 'none'}
            onBlur={
              validate
                ? ({ target }) =>
                    validateInput(value, target.attributes.validate.value)
                : null
            }
            {...props}
          />
        </label>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    );
  }
};

export default Input;
