import React from 'react';
import styles from './CustomerListEntry.module.css';
import CustomerDetails from './CustomerDetails';

const CustomerListEntry = ({ user, setMasterModal }) => {
  // console.log(user);
  const { data } = user;

  return (
    <li className={`flex_space_between align_center ${styles.singleCustomer}`}>
      <div>
        <p className={styles.nome}>{data.display_name}</p>
        <span className={styles.celular}>
          {data.metafields.celular} |{' '}
          <span className={styles.celular}>{data.user_email}</span>
        </span>
      </div>
      <div>
        <span
          onClick={() =>
            setMasterModal(
              <CustomerDetails
                customer={user}
                setMasterModal={setMasterModal}
              />,
            )
          }
          className={styles.optionsBtn}
        >
          •••
        </span>
      </div>
    </li>
  );
};

export default CustomerListEntry;
