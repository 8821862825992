
export const API_URL = 'https://rterra.xyz/json';

export function USER_POST(body) {
  return {
    url: API_URL + '/v1/user',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function USER_GET(token) {
  return {
    url: API_URL + '/v1/user',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function TOKEN_POST(body) {
  return {
    url: API_URL + '/jwt-auth/v1/token',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function TOKEN_VALIDATE_POST(token) {
  return {
    url: API_URL + '/jwt-auth/v1/token/validate',
    options: {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function CUSTOMERS_GET(token) {
  return {
    url: API_URL + '/v1/customers',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function AVA_CUPOM_POST(body, token) {
  return {
    url: API_URL + '/v1/cupom/disponiveis',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    },
  };
}

export function AVA_CUPOM_GET(token) {
  return {
    url: API_URL + '/v1/cupom/disponiveis',
    options: {
      method: 'GET',
    },
  };
}

export function CUPONS_GET(token) {
  return {
    url: API_URL + '/v1/cupom/vendidos',
    options: {
      method: 'GET',
    },
  };
}

export function CUSTOMER_CUPONS_GET(customer_id) {
  return {
    url: API_URL + '/v1/user/cupons/' + customer_id,
    options: {
      method: 'GET',
    },
  };
}

export function ADMIN_NOTIFICATIONS_GET(token) {
  return {
    url: API_URL + '/v1/admin/notifications',
    options: {
      method: 'GET',
      Authorization: 'Bearer ' + token,
    },
  };
}
export function ADMIN_NOTIFICATIONS_POST(token, body) {
  return {
    url: API_URL + '/v1/admin/notifications',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    },
  };
}
export function ADMIN_NOTIFICATIONS_PUT(body, token) {
  return {
    url: API_URL + '/v1/admin/notifications',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    },
  };
}
export function ADMIN_NOTIFICATIONS_DELETE(id, token) {
  return {
    url: API_URL + '/v1/admin/notifications/' + id,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}
export function AVA_CUPOM_PUT(body, token) {
  return {
    url: API_URL + '/v1/cupom/disponiveis',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    },
  };
}

export function CUPOM_PUT(body, token) {
  return {
    url: API_URL + '/v1/cupom/vendidos',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    },
  };
}

export function USER_PUT(body, token) {
  return {
    url: API_URL + '/v1/user',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    },
  };
}

export function USER_META_PUT(body, token) {
  return {
    url: API_URL + '/v1/user',
    options: {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    },
  };
}

export function CUPOM_POST(body, token) {
  return {
    url: API_URL + '/v1/cupom/vendidos',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    },
  };
}

export function CUPOM_DELETE(id, token) {
  return {
    url: API_URL + '/v1/cupom/vendidos/' + id,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  };
}
