import React from 'react';
import ButtonsYesNo from '../utilities/ButtonsYesNo';
import { ReactComponent as MessageIcon } from './../../Assets/icons/message.svg';
import { ReactComponent as DeleteIcon } from './../../Assets/icons/delete.svg';
import styles from './DebitAlert.module.css';
import useCupom from '../../Hooks/useCupom';
import { DataContext } from '../../Contexts/DataContext';

const DebitAlert = ({ debitNotify }) => {
  // console.log(debitNotify);
  const { fetchAllCupons } = React.useContext(DataContext);
  const token = window.localStorage.getItem('token');

  const { debitCupom, success, loading } = useCupom(
    debitNotify.debit_cupom_id,
    debitNotify.cupom_name,
  );

  React.useEffect(() => {
    success && fetchAllCupons(token, true);
  }, [success]);

  return (
    <li className={success ? 'debited' : undefined}>
      <span style={{ fontSize: '.675rem', fontStyle: 'italic' }}>
        Pagamento
      </span>
      {debitNotify.debit_message && (
        <span
          className={styles.customerMesssage}
          data-message={debitNotify.debit_message}
        >
          <MessageIcon />
        </span>
      )}
      <div className="flex_space_between align_end">
        <p>
          {debitNotify.cupom_name.split(' - ')[0]} - R${' '}
          {debitNotify.debit_amount},00
        </p>
        {success ? (
          <div className={styles.debitAlertSuccess}>
            <span className={styles.deleteIcon}>
              <DeleteIcon />
            </span>
            <p>Recebido!</p>
          </div>
        ) : (
          <ButtonsYesNo
            loading={{ state: loading, message: 'Aguarde...' }}
            targetID={debitNotify.debit_cupom_id}
            yesAction={(event) =>
              debitCupom(event, {
                debit_amount: debitNotify.debit_amount,
                debit_message: debitNotify.debit_message,
                notification_ID: debitNotify.ID,
              })
            }
          />
        )}
      </div>
    </li>
  );
};

export default DebitAlert;
