import React from 'react';
import styles from './CupomDetails.module.css';

const CupomDetails = ({ cupom }) => {
  // console.log(cupom);
  return (
    <div className={styles.CupomDetails}>
      <h1>Detalhes do cupom</h1>

      <div className={styles.detailsGrid}>
        <dl>
          <dd>#{cupom.ID}</dd>
          <dt>Cupom</dt>
          <dd>{cupom.post_title.split(' - ')[1]}</dd>
          <dt>Cliente</dt>
          <dd>{cupom.post_title.split(' - ')[0]}</dd>
          <div className={styles.dlFlex}>
            <div>
              <dt>Saldo atual</dt>
              <dd>R$ {cupom.metafields.current_value},00</dd>
            </div>
            <div>
              <dt>Validade</dt>
              <dd>{cupom.metafields.expire_date}</dd>
            </div>
          </div>
        </dl>

        <div className={styles.history}>
          <div>
            <ul>
              {/* {console.log(cupom.uses == false)} */}
              {cupom.uses != '' ? (
                cupom.uses.map((use, i) => {
                  return (
                    <li key={'use-' + i}>
                      <span>{use.date}</span>
                      <p
                        style={{ marginBottom: '10px' }}
                        className={`${!use.description && 'empty'}`}
                      >
                        {use.description
                          ? use.description
                          : 'Sem descrição informada'}
                      </p>
                      <p
                        style={{
                          fontSize: '.7rem',
                          fontWeight: '500',
                          fontStyle: 'italic',
                        }}
                      >
                        Recebido por: {use.admin}
                      </p>
                      <p>Valor debitado: R$ {use.debited_amount},00</p>
                    </li>
                  );
                })
              ) : (
                <li
                  style={{
                    textAlign: 'center',
                    fontSize: '.875rem',
                    fontStyle: 'italic',
                  }}
                >
                  Este cupom ainda não foi utilizado
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CupomDetails;
