import React from 'react';

const Loading = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <p>Carregando...</p>
    </div>
  );
};

export default Loading;
