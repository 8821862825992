import React from 'react';
import { CUPOM_DELETE } from '../../ApiSettings';
import { ReactComponent as DeleteIcon } from '../../Assets/icons/delete.svg';
import { ReactComponent as InfoIcon } from '../../Assets/icons/document.svg';
import useFetch from '../../Hooks/useFetch';
import { DataContext } from '../../Contexts/DataContext';
import CupomDetails from './../Modals/CupomDetails';
import ButtonsYesNo from '../utilities/ButtonsYesNo';

const CupomCustomerDetail = ({ cupom, customer, setMasterModal }) => {
  const [deleteAlert, setDeleteAlert] = React.useState(false);

  const { fetchAllCupons } = React.useContext(DataContext);

  const { loading, request } = useFetch();
  const token = window.localStorage.getItem('token');

  React.useCallback(() => {}, []);

  async function deleteCupom({ currentTarget }) {
    const id_to_delete = +currentTarget.dataset.cupomId;
    const cupom_delete = CUPOM_DELETE(id_to_delete, token);
    const { response } = await request(cupom_delete.url, cupom_delete.options);
    if (response.ok) {
      console.log('deletou');
      fetchAllCupons(token, true);
    }
  }

  const is_expired = cupom.metafields.is_expired != '';
  const is_outfunded = cupom.metafields.is_outfunded != '';

  return (
    <div
      data-cupom-id={cupom.ID}
      className={`cupom`}
      data-status={is_expired || is_outfunded ? 'inactive' : 'active'}
    >
      <div className={'cupomName'}>
        <p>{cupom.post_title.split(' - ')[1]}</p>
        <span>
          <span>#{cupom.ID}</span> | {''}
          <span>
            {is_expired && 'EXPIRADO'}
            {is_outfunded && 'FINALIZADO'}{' '}
            {!is_expired &&
              !is_outfunded &&
              `Val: ${cupom.metafields.expire_date}`}
          </span>
        </span>
      </div>

      <div className={'cupomRight'}>
        {!is_expired && !is_outfunded && (
          <div className={'currentValue'}>
            <span>Saldo atual</span>
            <span>R$ {cupom.metafields.current_value},00</span>
          </div>
        )}

        <div className="cupomOptions">
          <span
            className={`delete ${deleteAlert && 'active'}`}
            onClick={() => setDeleteAlert(!deleteAlert)}
            data-cupom-id={cupom.ID}
          >
            <DeleteIcon />
          </span>
          <span onClick={() => setMasterModal(<CupomDetails cupom={cupom} />)}>
            <InfoIcon />
          </span>
        </div>
      </div>

      {deleteAlert && (
        <div className={'deleteAlert'}>
          <div>
            <span>Excluir cupom?</span>
            <p>Não será mais possível utilizar ou reativar esse cupom.</p>
          </div>
          <ButtonsYesNo
            yesAction={deleteCupom}
            noAction={setDeleteAlert}
            targetID={cupom.ID}
            loading={{ state: loading, message: 'Excluindo...' }}
          />
        </div>
      )}
    </div>
  );
};

export default CupomCustomerDetail;
