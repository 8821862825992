import React from 'react';
import { AVA_CUPOM_PUT } from '../../ApiSettings';
import useFetch from '../../Hooks/useFetch';
import styles from './Switcher.module.css';

const Switcher = ({ status, refresh, ...props }) => {
  const [enabled, setEnabled] = React.useState(
    status === 'publish' ? true : false,
  );
  const { loading, error, setError, request } = useFetch();
  async function handleSwitch() {
    const put_data = {
      ID: props.post.ID,
      post_status: enabled ? 'draft' : 'publish',
    };

    const token = window.localStorage.getItem('token');
    const { url, options } = AVA_CUPOM_PUT(put_data, token);

    const { response } = await request(url, options);
    if (response.ok) refresh();

    setEnabled(!enabled);
  }

  return (
    <span
      className={styles.switcher}
      data-switch={enabled && 'enabled'}
      onClick={handleSwitch}
    ></span>
  );
};

export default Switcher;
