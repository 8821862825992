import React from 'react';
import { AVA_CUPOM_GET } from '../../ApiSettings';
import useFetch from '../../Hooks/useFetch';
import useOrderElements from '../../Hooks/useOrderElements';
import Loading from '../utilities/Loading';
import RegCupomListEntry from './RegCupomListEntry';
import styles from './RegisteredCupons.module.css';

const RegisteredCupons = () => {
  const [cupons, setCupons] = React.useState(null);

  const { loading, error, setError, request } = useFetch();
  const { url, options } = AVA_CUPOM_GET();

  const { orderElements } = useOrderElements();

  async function fetchAllCupons() {
    const { json } = await request(url, options);
    setCupons(orderElements(json));
  }

  React.useEffect(() => {
    fetchAllCupons();
  }, []);

  return (
    <div className={styles.registeredCupons}>
      <h2>Cupons registrados</h2>
      <div className={styles.registeredCuponsList}>
        {cupons ? (
          <ul>
            {cupons.map((cupom) => {
              // console.log(cupom);
              return (
                <RegCupomListEntry
                  cupom={cupom}
                  key={cupom.ID}
                  fetchAllCupons={fetchAllCupons}
                />
              );
            })}
          </ul>
        ) : (
          <div style={{ marginTop: '40px' }}>
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisteredCupons;
