import React from 'react';
import { UserContext } from '../../Contexts/UserContext';
import CupomListEntry from '../CupomListEntry';
import styles from './CustomerCupons.module.css';

const CustomerCupons = () => {
  const { customerCupons, role, data } = React.useContext(UserContext);
  const [cupomTooltip, setCupomTooltip] = React.useState('');

  customerCupons && console.log(customerCupons);

  function handleModal(element) {
    if (cupomTooltip && element !== cupomTooltip) {
      document.getElementById('debitCupom').classList.add('animeBottom');
      setTimeout(() => {
        document.getElementById('debitCupom').classList.remove('animeBottom');
        setCupomTooltip('');
        setCupomTooltip(element);
      }, 300);
    } else setCupomTooltip(element);
  }

  return (
    <div>
      <h1>Meus Cupons</h1>
      <div className={styles.cuponsGrid}>
        <div>
          <h2>Ativos</h2>
          <ul>
            {customerCupons &&
              customerCupons.map((cupom) => {
                return (
                  <CupomListEntry
                    cupom={cupom}
                    key={cupom.ID}
                    handleModal={handleModal}
                    hasTooltip={cupomTooltip ? true : false}
                    currentTooltip={cupomTooltip && cupomTooltip.props.cupom.ID}
                  />
                );
              })}
            <li></li>
          </ul>
        </div>

        <div className={styles.tooltip}>{cupomTooltip && cupomTooltip}</div>
      </div>
    </div>
  );
};

export default CustomerCupons;
