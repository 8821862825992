import React from 'react';
import {
  ADMIN_NOTIFICATIONS_POST,
  ADMIN_NOTIFICATIONS_PUT,
  CUPOM_PUT,
} from '../ApiSettings';
import useFetch from './useFetch';
import { UserContext } from '../Contexts/UserContext';

const useCupom = (cupom_id, cupom_name) => {
  const { data, getCustomerCupons } = React.useContext(UserContext);
  const { request, loading, error, setError } = useFetch();
  const [success, setSuccess] = React.useState(false);

  async function debitCupomNotify(event) {
    event.preventDefault();
    const token = window.localStorage.getItem('token');
    const amount_to_debit = event.target[0].value;
    const debit_comment = event.target[1].value;
    const notification_post_body = {
      amount_to_debit: amount_to_debit,
      debit_customer_comment: debit_comment,
      post_id: cupom_id,
      post_title: cupom_name,
    };

    // console.log(notification_post_body);

    const { url, options } = ADMIN_NOTIFICATIONS_POST(
      token,
      notification_post_body,
    );
    const { response, json } = await request(url, options);
    if (response.ok) {
      getCustomerCupons(data.id);
      setSuccess(true);
    }
  }

  async function debitCupom(event, details = null) {
    event.preventDefault();
    const token = window.localStorage.getItem('token');
    const amount_to_debit =
      event.type === 'submit' ? event.target[0].value : details.debit_amount;
    const debit_description =
      event.type === 'submit' ? event.target[1].value : details.debit_message;

    const put_cupom_body = {
      amount_to_debit: amount_to_debit,
      debit_description: debit_description,
      post_id: +cupom_id,
      admin: data.nome,
    };
    // console.log(put_cupom_body);
    const { url, options } = CUPOM_PUT(put_cupom_body, token);
    const { response, json } = await request(url, options);
    if (event.type === 'click') {
      const notification_put = ADMIN_NOTIFICATIONS_PUT(
        {
          ID: details.notification_ID,
          is_read: true,
          is_debited: true,
          post_id: +cupom_id,
        },
        token,
      );
      const n_put_response = await request(
        notification_put.url,
        notification_put.options,
      );
      console.log(n_put_response);
    }

    if (response.ok) {
      setSuccess(true);
    }
  }

  return { debitCupom, debitCupomNotify, success, error, loading, setSuccess };
};

export default useCupom;
