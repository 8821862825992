import React from 'react';
// import useSearch from '../../Hooks/useSearch';
import styles from './SearchInput.module.css';

const SearchInput = ({ type, data, setQueryData, placeholder, ...props }) => {
  function handleChange(query) {
    let queryData = {};
    if (type === 'customers') {
      queryData = data.flatMap((_data) => {
        if (
          _data.data.display_name.toUpperCase().startsWith(query.toUpperCase())
        )
          return _data;
        else return [];
      });
    } else if (type === 'cupom') {
      queryData = data.flatMap((_data) => {
        if (
          _data.post_title
            .toUpperCase()
            .split(' - ')[0]
            .startsWith(query.toUpperCase())
        )  
          return _data;
        else return [];
      });
    }
    setQueryData(query === '' ? null : queryData);
  }

  return (
    <div className={styles.input}>
      <input
        type="text"
        autoCorrect="false"
        autoComplete="false"
        placeholder={placeholder}
        onChange={({ target }) => handleChange(target.value)}
        {...props}
      />
    </div>
  );
};

export default SearchInput;
