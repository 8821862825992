import React from 'react';
import styles from './ButtonsYesNo.module.css';

const ButtonsYesNo = ({ loading, yesAction, noAction, targetID }) => {
  return (
    <div className={styles.buttons}>
      {loading.state ? (
        <p>{loading.message}</p>
      ) : (
        <>
          {' '}
          <button data-cupom-id={targetID} onClick={yesAction}>
            sim
          </button>
          <button onClick={() => noAction(false)}>não</button>
        </>
      )}
    </div>
  );
};

export default ButtonsYesNo;
