import React from 'react';

const useOrderElements = () => {
  const orderElements = React.useCallback((rawElements) => {
    let orderedElements = [];
    rawElements.forEach((element) => {
      if (
        orderedElements.length === 0 ||
        +element.metafields.valor_saldo >
          +orderedElements[orderedElements.length - 1].metafields.valor_saldo
      ) {
        orderedElements.push(element);
      } else if (
        +element.metafields.valor_saldo <
        +orderedElements[0].metafields.valor_saldo
      ) {
        orderedElements.unshift(element);
      } else {
        orderedElements.some((cp, i) => {
          if (+element.metafields.valor_saldo < +cp.metafields.valor_saldo) {
            orderedElements.splice(i, 0, element);
            return true;
          } else return false;
        });
      }
    });
    return orderedElements;
  }, []);

  return { orderElements };
};

export default useOrderElements;
