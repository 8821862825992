import React from 'react';
import styles from './CustomerDetailsField.module.css';

const CustomerDetailsField = ({ label, value }) => {
  return (
    <div className={styles.customerField}>
      <span className={styles.label}>{label}</span>
      <p className={styles.value}>
        {value ? (
          value
        ) : (
          <i style={{ opacity: '.65', fontSize: '.825rem' }}>
            Dado não cadastrado
          </i>
        )}
      </p>
    </div>
  );
};

export default CustomerDetailsField;
