import React from 'react';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext';
import styles from './Home.module.css';
import LoginForm from './Login/LoginForm';
import { ReactComponent as LockIcon } from './../Assets/icons/lock.svg';
import CustomerCupons from './customers/CustomerCupons';
import CustomerProfile from './customers/CustomerProfile';

const Home = () => {
  const { data, login, role, userLogout } = React.useContext(UserContext);

  return (
    <section className={styles.Home}>
      <div className="container">
        <div className={styles.leftBar}>
          {/* {login && console.log(data)} */}
          <>
            <div className={styles.greeting}>
              <span>Olá,</span>
              <p>{login ? data.nome : 'visitante'}</p>

              {role === 'administrator' && (
                <Link to="/admin">
                  <div className={styles.adminOptions}>
                    <span>
                      <LockIcon />
                    </span>
                    <span>Acesse o painel administrativo</span>
                  </div>
                </Link>
              )}
            </div>
            {login ? (
              <div className={styles.customerMenu}>
                <hr />
                <ul>
                  <li>
                    <NavLink to="/meu-perfil">Meu perfil</NavLink>
                  </li>
                  <li>
                    <NavLink to="/meus-cupons">Meus cupons</NavLink>
                  </li>

                  <li onClick={userLogout}>Sair</li>
                </ul>
              </div>
            ) : (
              <>
                <p style={{ marginBottom: '18px' }}>
                  Identifique-se para utilizar o sistema!
                </p>
                <LoginForm />
              </>
            )}
          </>
        </div>
        <div className={styles.mainContent}>
          <Routes>
            <Route path="/meus-cupons" element={<CustomerCupons />} />
            <Route path="/meu-perfil" element={<CustomerProfile />} />
          </Routes>
        </div>
      </div>
    </section>
  );
};

export default Home;
