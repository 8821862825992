import React, { useContext } from 'react';
import useFetch from '../Hooks/useFetch';
import {
  ADMIN_NOTIFICATIONS_GET,
  CUPONS_GET,
  CUSTOMERS_GET,
} from '../ApiSettings';

export const DataContext = React.createContext();

export const DataStorage = ({ children }) => {
  const [masterModal, setMasterModal] = React.useState(false);
  const [customers, setCustomers] = React.useState(null);
  const [cupons, setCupons] = React.useState(null);
  const [notifications, setNotifications] = React.useState(null);
  const { request, loading } = useFetch();

  async function fetchAllCustomers(token, force = false) {
    if (customers === null || force === true) {
      const { url, options } = CUSTOMERS_GET(token);
      const { json, response } = await request(url, options);
      setCustomers(json);
      // console.log('fetchAllCustomers');
    }
  }

  async function fetchAllCupons(token, force = false) {
    if (cupons === null || force === true) {
      const { url, options } = CUPONS_GET(token);
      const { json, response } = await request(url, options);

      setCupons(json);
      // console.log(JSON.parse(json[0].metafields.pending_debit));
    }
  }

  const notificationsFetch = React.useCallback(() => {
    setTimeout(() => {
      console.log('notificationsFetch');
      checkDataBase(
        window.localStorage.getItem('token'),
        ADMIN_NOTIFICATIONS_GET,
      );
    }, 5000);
  }, []);

  async function checkDataBase(token, requestType) {
    const { url, options } = requestType(token && token);
    const { json, response } = await request(url, options);

    notifications
      ? json.length !== notifications.length && setNotifications(json)
      : setNotifications(json);

    notificationsFetch();
  }

  React.useEffect(() => {
    fetchAllCustomers(window.localStorage.getItem('token'));
    fetchAllCupons(window.localStorage.getItem('token'));
    checkDataBase(
      window.localStorage.getItem('token'),
      ADMIN_NOTIFICATIONS_GET,
    );
  }, []);
  return (
    <DataContext.Provider
      value={{
        fetchAllCustomers,
        customers,
        fetchAllCupons,
        cupons,
        loading,
        masterModal,
        setMasterModal,
        checkDataBase,
        notifications,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
