import React from 'react';
import Button from '../utilities/Button';
import useModal from '../../Hooks/useModal';
import styles from './AdminHeader.module.css';
import { NavLink } from 'react-router-dom';
import { DataContext } from '../../Contexts/DataContext';
import { ReactComponent as NotificationsIcon } from './../../Assets/icons/bell.svg';

const AdminHeader = () => {
  const { masterModal, setMasterModal, notifications } =
    React.useContext(DataContext);
  const modal = useModal(setMasterModal);
  let newNotifications =
    notifications && notifications.filter((n) => n.is_read == false);
  return (
    <>
      {masterModal && <modal.ModalElement>{masterModal}</modal.ModalElement>}
      <nav className={styles.adminHeader} id="adminHeaderNav">
        <div className={styles.option}>
          <NavLink to="/admin" end>
            <Button>Início</Button>
          </NavLink>
        </div>

        <div className={styles.option}>
          <NavLink to="/admin/cupons">
            <Button>Gerenciar cupons</Button>
          </NavLink>
        </div>

        <div className={styles.option}>
          <NavLink to="/admin/clientes">
            <Button>Gerenciar clientes</Button>
          </NavLink>
        </div>
        <span
          className={`${styles.notificationsIcon} ${
            newNotifications && newNotifications.length > 0 && 'active'
          }`}
          data-new={newNotifications ? newNotifications.length : null}
        >
          <NotificationsIcon />
        </span>
      </nav>
    </>
  );
};

export default AdminHeader;
