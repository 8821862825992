import React from 'react';
import { UserContext } from '../../Contexts/UserContext';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { DataStorage } from '../../Contexts/DataContext';
import styles from './Admin.module.css';
import AdminHeader from './AdminHeader';
import AdminHome from './AdminHome';
import ManageCupons from './ManageCupons';
import ManageCustomers from './ManageCustomers';
import useModal from '../../Hooks/useModal';
import Head from '../Head';

const Admin = () => {
  const { role } = React.useContext(UserContext);
  const navigate = useNavigate();
  const [masterModal, setMasterModal] = React.useState(false);
  const modal = useModal(setMasterModal);

  function navigateHome() {
    setTimeout(() => {
      navigate('/');
    }, 3000);
  }
  return (
    <section>
      <Head
        title="Admin | App de Cupons"
        description="Painel Administrativo do App de Cupons"
      />
      <div className="container" style={{ position: 'relative' }}>
        <DataStorage>
          {masterModal && (
            <modal.ModalElement>{masterModal}</modal.ModalElement>
          )}
          <span style={{ textAlign: 'center', display: 'block' }}>
            Painel administrativo
          </span>
          <AdminHeader />

          <Routes>
            <Route path="/" element={<AdminHome />} />
            <Route
              path="cupons"
              element={<ManageCupons setMasterModal={setMasterModal} />}
            />
            <Route
              path="clientes"
              element={<ManageCustomers setMasterModal={setMasterModal} />}
            />
          </Routes>
        </DataStorage>
      </div>
    </section>
  );

  // if (role != 'administrator') {
  //   return (
  //     <div className={styles.notAuth}>
  //       <p>Acesso não autorizado.</p>
  //       <p>Redirecionando para a página inicial...</p>
  //       {navigateHome()}
  //     </div>
  //   );
  // } else {
  //   return (
  //     <section>
  //       <div className="container">
  //         <AdminHeader />
  //         <Routes>
  //           <Route path="/" element={<AdminHome />} />
  //           <Route path="cupons" element={<ManageCupons />} />
  //           <Route path="clientes" element={<ManageCustomers />} />
  //         </Routes>
  //       </div>
  //     </section>
  //   );
  // }
};

export default Admin;
