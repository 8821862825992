import React from 'react';
import styles from './AdminHome.module.css';
import { DataContext } from '../../Contexts/DataContext';
import DebitAlert from './DebitAlert';

const AdminHome = () => {
  const { notifications } = React.useContext(DataContext);

  return (
    <div className={styles.AdminHome}>
      <div className={styles.leftBar}>
        <div className={styles.panelBox}>
          <span>Notificações</span>
          <hr />
          <ul className={styles.notificationsList}>
            {notifications &&
              notifications.map((n) => {
                return n.is_debited == false ? <DebitAlert debitNotify={n} key={n.ID} /> : null;
              })}
          </ul>
        </div>
      </div>
      <div className={styles.gridArea}>Grid</div>
    </div>
  );
};

export default AdminHome;
