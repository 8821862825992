import React from 'react';
import modalStyles from './../../Hooks/Modal.module.css';
import CupomDetails from './CupomDetails';
import { ReactComponent as Info } from '../../Assets/icons/document.svg';
import { DataContext } from '../../Contexts/DataContext';

const DebitCupomSuccess = ({ cupom, formRef }) => {
  const { fetchAllCupons, setMasterModal } = React.useContext(DataContext);
  const token = window.localStorage.getItem('token');
  React.useEffect(() => {
    fetchAllCupons(token, true);
  }, []);

  return (
    <div>
      <p className={modalStyles.successTitle}>
        O cupom foi debitado com sucesso.
      </p>

      <span>Confira abaixo os detalhes:</span>
      <div className={modalStyles.successDetails}>
        <p>{cupom.post_title.split(' - ')[1]}</p>
        <p>{cupom.post_title.split(' - ')[0]}</p>
        <p>Val: {cupom.metafields.expire_date}</p>

        {formRef.current && (
          <p>Valor debitado: R$ {formRef.current[0].value || 0},00</p>
        )}
        {formRef.current && (
          <p>
            Saldo atual: R${' '}
            {+cupom.metafields.current_value - +formRef.current[0].value}
            ,00
          </p>
        )}
        <div className={modalStyles.successDetailsOptions}>
          <span
            onClick={() => {
              setMasterModal(false);
              setMasterModal(<CupomDetails cupom={cupom} />);
            }}
          >
            <Info />
          </span>
        </div>
      </div>
      <p>
        Os detalhes dessa transação foram enviados para o email cadastrado do
        cliente.
      </p>
    </div>
  );
};

export default DebitCupomSuccess;
