import React from 'react';
import Button from '../utilities/Button';

const DebitCupomSubmitted = ({ cupom, formRef, handleModal, setSuccess }) => {
  return (
    <div>
      <span>Tudo certo!</span>
      <p>
        Seu pagamento foi enviado e aparecerá como pendente até a confirmação do
        vendedor.
      </p>
      <Button
        onClick={() => {
          setSuccess(false);
          handleModal('');
        }}
      >
        Fechar
      </Button>
    </div>
  );
};

export default DebitCupomSubmitted;
